<!-- 新增代表队对话框 -->
<template>
	<div>
		<el-dialog class="common-dialog  add-team-dialog-form" :title="title" :visible.sync="teamDialogVisible"
			width="520px" destroy-on-close :close-on-click-modal="false">
			<div style="margin-top: 20px;">
				<el-form ref="addTeamForm" :model="addTeamForm" :rules="addTeamFormRules" label-width="130px"
					inline  inline-message>
					<el-form-item label="代表队：" prop="name">
						<el-input size="small" class="input-item" style="margin-top:0px;" maxlength="100" v-model="addTeamForm.name"
							placeholder="请输入比代表队名称" :clearable="true">
						</el-input>
					</el-form-item>
					<el-form-item label="领队：" prop="leader">
						<el-input size="small" class="input-item" style="margin-top:0px;" maxlength="30" v-model="addTeamForm.leader"
							placeholder="请输入领队姓名" :clearable="true">
						</el-input>
					</el-form-item>
					<el-form-item label="教练：" prop="coach">
						<el-input  size="small" class="input-item" style="margin-top:0px;" maxlength="30" v-model="addTeamForm.coach"
							placeholder="请输入比教练姓名" :clearable="true">
						</el-input>
					</el-form-item>
					<el-form-item label="联系方式：" prop="contactPhone">
						<el-input size="small" class="input-item" style="margin-top:0px;" maxlength="11"
							v-model="addTeamForm.contactPhone" :clearable="true" placeholder="请输入联系方式">
						</el-input>
					</el-form-item>
					<el-form-item label="收货信息：" prop="receivingGoodsInfo">
						<el-input size="small" type="textarea" :rows="4" class="input-item" style="margin-top:0px;" maxlength="200"
							v-model="addTeamForm.receivingGoodsInfo" :clearable="true"
							placeholder="收货信息（含收货地址、收货人及联系电话），方便寄背号、证书、奖品等">
						</el-input>
					</el-form-item>

				</el-form>
			</div>
			<template #footer>
				<el-button size="small" @click="teamDialogVisible = false">取 消</el-button>
				<el-button size="small" type="danger" @click="joinTeamSave" class="dialog-submit-btn"  style="background-color: rgb(243, 44, 108);color:white;border-color:rgb(243, 44, 108);">提交</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui'
	import {
		handleJoinTeamSave
	} from '@index/api/team/teaminfo';

	export default {
		name: 'adddelegateteamdialog',
		components: {

		},
		props: {
			title: {
				type: String,
				default: '新增代表队'
			}
		},
		data() {
			return {
				teamDialogVisible: false,
				addTeamForm: {},
				addTeamFormRules: {
					name: [{
						required: true,
						message: '请输入比代表队名称!',
						trigger: 'blur'
					}],
					leader: [{
						required: true,
						message: '请输入领队姓名!',
						trigger: 'blur'
					}],
					coach: [{
						required: true,
						message: '请输入比教练姓名!',
						trigger: 'blur'
					}],
					contactPhone: [{
						required: true,
						message: '请输入联系方式!',
						trigger: 'blur'
					}]
				},
			};
		},
		watch: {

		},
		created() {

		},
		mounted() {

		},
		destroyed() {
			// window.removeEventListener('storage', this.afterQRScan)
		},
		methods: {
			showDialog() {
				this.teamDialogVisible = true;
			},
			closeDialog() {
				this.teamDialogVisible = false;
			},

			joinTeamSave() { //保存代表队信息
				this.$refs.addTeamForm.validate((valid) => {
					if (valid) {
						handleJoinTeamSave(this.addTeamForm).then(res => {
							if (res.status == 200) {
								Message({
									message: res.msg,
									type: 'success',
									duration: 5 * 1000
								})
								
								this.addTeamForm={};
							//	this.$emit('setCurrTeam', res.data);
							///	this.$parent.setCurrTeam(res.data); //回调我的代表队
								this.$parent.queryMyTeams(); //回调我的代表队
							//	this.teamDialogVisible = false;
								this.closeDialog();
							} else {
								Message({
									message: res.msg,
									type: 'error',
									duration: 5 * 1000
								})
							}
						});

					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},




		}
	}
</script>

<style scoped>
	.common-dialog {
		.el-dialog__body {
			padding: 0px 20px;
		}
	}

	.add-team-dialog-form .el-form-item {
		width: 100%;
		
	}

	::v-deep .add-team-dialog-form .el-form-item__content {
		width: 300px;
		
	}
</style>