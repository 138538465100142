import {

	Message
} from 'element-ui'
import {
	handleMyTeams,
	handlePageQueryPlayers,
	handleQueryMatchRelateSet,
	handleQueryGroupsettinByPlayers,
	handleMyPlayerClassify,
	handleSubmitOnlineSignup,
	handlePageQuerySignupResult,
	handledeleteSignupPlayer,
	handleSaveCurrentTeam,
	handleQueryCurrentTeam,
	handleDeleteSignupPlayers,
	handleMyTeamOfSignup
} from '@index/api/joinmatch/onlinesignup';
import {
	handleStreamlineDetails

} from '@index/api/joinmatch/matchinfo';
import {
	handleDownloadOnlineSignupTable
} from '@index/api/mymatchs/onlinesignuptable';
import matchtitle from '../../components/matchtitle';
import adddelegateteamdialog from '../../components/adddelegateteamdialog';
import addplayerdialog from '../../components/addplayerdialog';
// import {
// 	cloneDeep
// } from 'lodash'
export default {
	name: 'onlinesignuptable',
	components: {
		matchtitle,
		adddelegateteamdialog,
		addplayerdialog
	},
	data() {
		return {
			states: ["上报成功", "通过审核", "撤消中"],
			matchRules: ['等级赛', '名次赛', '奖金赛', '不限', '淘汰赛', '精英赛', '竞技赛', '进阶赛'],
			matchInfo: {},
			signupResultForm: {},
			grouprForm: {},
			groupDialogVisible: false,
			teamFormDialogVisible: false,
			playerDialogVisible: false,
			form: {},
			myTeamsData: [], //我的代表队
			myPlayerForm: {},
			myPlayersData: [],
			teamForm: {
				id: ''
			},
			tableData: [],
			pageSizeMatchPlayer: 30,
			currPageMatchPlayer: 1,
			totalCountMatchPlayer: 0,
			playerClassifys: [],
			selectedPlayers: [],
			dialogSelectedPlayers: [],
			pageSizeGroup: 30,
			currPageGroup: 1,
			totalCountGroup: 0,
			tableDataGroups: [],
			pageSizeSignupResult: 20,
			currPageSignupResult: 1,
			totalCountSignupResult: 0,
			tableDataSignupResult: [],
			selectedGroups: [],
			dialogSelectedGroups: [],
			matchId: sessionStorage.getItem('joinMatchId'),
			matchRelateSet: {},
			signupFee: 0,
			groupSignupFee: 0,
			sumSignupFee: 0,
			deleteSelectedPlayers: [],
			myTeamsOfSignup: [],

		};
	},
	watch: {
		selectedGroups() {
			this.calcSignupFee();
		},
		dialogSelectedGroups() {
			this.calcGroupSignupFee();
		}
	},
	created() {
		this.pageQuerySignupResult();
		this.queryMyTeams();
		this.myTeamOfSignup();
		this.matchdetails();

	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChangeSignupResult(pageSize) { //改变页面大小(组别)
			this.pageSizeSignupResult = pageSize;
			this.pageQuerySignupResult();
		},
		handleCurrentChangeSignupResult(currPage) { //跳转到某一页（组别）
			this.currPageSignupResult = currPage;
			this.pageQuerySignupResult();
		},

		handleSizeChangeGroup(pageSize) { //改变页面大小(组别)
			this.pageSizeGroup = pageSize;
			this.queryGroupsettinByPlayers();
		},
		handleCurrentChangeGroup(currPage) { //跳转到某一页（组别）
			this.currPageGroup = currPage;
			this.queryGroupsettinByPlayers();
		},
		handleSizeChangeMatchPlayer(pageSize) { //改变页面大小(参赛选手)
			this.pageSizeMatchPlayer = pageSize;
			this.pageQueryPlayers();
		},
		handleCurrentChangeMatchPlayer(currPage) { //跳转到某一页（参赛选手）
			this.currPageMatchPlayer = currPage;
			this.pageQueryPlayers();
		},
		queryMyTeams() { //我的参赛队
			handleMyTeams(this.matchId).then(res => {
				if (res.status == 200) {
					this.myTeamsData = res.data;
					handleQueryCurrentTeam(this.matchId).then(res2 => {
						if (res2.status == 200 && res2.data.id) {
							this.teamForm.id = res2.data.id;
							this.myTeamsData.forEach((item) => {
								if (this.teamForm.id == item.id) {
									this.form.teamId = item.id;
									this.form.teamSignupAuth = item.signupAuth
									this.form.teamName = item.name;
									return;
								}
							});

						}
					});


				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					});
				}

			})
		},
		selectTeamEvent() { //选择代表队
			this.teamFormDialogVisible = false;
			if (this.teamForm.id) {
				this.myTeamsData.forEach((item) => {
					if (this.teamForm.id == item.id) {
						this.form.teamId = item.id;
						this.form.teamSignupAuth = item.signupAuth
						this.form.teamName = item.name;
						return;
					}
				});
			}
			//记录当前代表队
			handleSaveCurrentTeam({
				teamId: this.teamForm.id,
				matchId: this.matchId
			}).then(() => {
				this.queryMyTeams();
			});


		},
		pageQueryPlayers() { //分页查询选手
			handlePageQueryPlayers({
				pageSize: this.pageSizeMatchPlayer,
				currPage: this.currPageMatchPlayer,
				con: this.myPlayerForm.con,
				classifyId: this.myPlayerForm.classifyId,
				matchId: this.matchId, //(计算年龄)
				sortWay: [{
					fieldName: 'createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.myPlayersData = res.data;
					this.currPageMatchPlayer = res.currPage;
					this.pageSizeMatchPlayer = res.pageSize;
					this.totalCountMatchPlayer = res.totalCount;

					this.$nextTick(() => {
						this.$refs.playersTable.bodyWrapper.scrollTop = 0
					})
					var _this = this;
					setTimeout(function () {
						_this.dialogSelectedPlayers.forEach((item) => {
							_this.myPlayersData.forEach((item2) => {
								if (item.id == item2.id) {
									_this.$refs.playersTable.toggleRowSelection(item2, true);
									return;
								}
							});
						});
					}, 300);


				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					});
				}

			})
		},
		fastQueryMyPlayers() { //快速查询我的选手
			this.currPageMatchPlayer = 1;
			this.pageQueryPlayers();
		},
		openPlayerDialog() { //打开选手对话框
			if (!this.form.teamId) {
				Message({
					type: 'error',
					message: '请选择代表队',
					duration: 5000
				});
				return;
			}
			this.playerDialogVisible = true;
			this.dialogSelectedPlayers = [];
			this.dialogSelectedPlayers = [...this.selectedPlayers];
			this.fastQueryMyPlayers();
			this.myPlayerClassify();
			this.queryMatchRelateSet();
		},
		myPlayerClassify() { //我的分类
			handleMyPlayerClassify().then(res => {
				if (res.status == 200) {
					this.playerClassifys = res.data;
				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					});
				}
			})
		},
		selectTablePlayersAll(selection) { //选择所有选手				
			if (selection.length <= 0) {
				this.dialogSelectedPlayers = [];
			} else {
				this.dialogSelectedPlayers = [];
				selection.forEach((item) => {
					this.dialogSelectedPlayers.push({
						id: item.id,
						name: item.name,
						sex: item.sex,
						age: item.age,
						realNameAuthStatus: item.realNameAuthStatus
					});
				});
			}
		},
		selectTablePlayers(selection, row) { //选择选手			
			var temp = [];
			var isExistFlag = false;
			this.dialogSelectedPlayers.forEach((item) => {
				if (item.id == row.id) {
					isExistFlag = true;
				} else {
					temp.push(item);
				}
			});
			if (isExistFlag) {
				this.dialogSelectedPlayers = temp;
			} else {
				if (!this.dialogSelectedPlayers) {
					this.dialogSelectedPlayers = [];
				}
				this.dialogSelectedPlayers.push({
					id: selection[selection.length - 1].id,
					name: selection[selection.length - 1].name,
					sex: selection[selection.length - 1].sex,
					age: selection[selection.length - 1].age,
					realNameAuthStatus: selection[selection.length - 1].realNameAuthStatus
				});
			}

		},
		// setTableRowKey(row) { //设置选手列表翻页回显
		//   return row.id;
		// },
		removeSelectPlayer(playerId) { //移除选手

			this.myPlayersData.forEach((item) => {
				if (item.id == playerId) {
					this.$refs.playersTable.toggleRowSelection(item, false);
					return;
				}
			});
			//从选择的选手中删除
			var temp = [];
			this.selectedPlayers.forEach((item) => {
				if (item.id != playerId) {
					temp.push(item);
				}
			});
			this.selectedPlayers = temp;

			this.selectedGroups = [];

			temp = [];
			this.dialogSelectedPlayers.forEach(item => {
				if (playerId != item.id) {
					temp.push(item);
				}
			})
			this.dialogSelectedPlayers = temp;

		},
		queryGroupsettinByPlayers() { //根据选手查询组别

			var playerIds = [];
			this.selectedPlayers.forEach((item) => {
				playerIds.push(item.id);
			});
			//const dialogSelectedGroups = cloneDeep(this.dialogSelectedGroups);
			var temp = this.dialogSelectedGroups;
			handleQueryGroupsettinByPlayers({
				pageSize: this.pageSizeGroup,
				currPage: this.currPageGroup,
				con: this.grouprForm.con,
				matchRules: this.grouprForm.matchRules,
				playerIds: playerIds,
				matchId: this.matchId
			}).then(res => {
				if (res.status == 200) {
					this.tableDataGroups = res.data;
					this.currPageGroup = res.currPage;
					this.pageSizeGroup = res.pageSize;
					this.totalCountGroup = res.totalCount;
					this.$nextTick(() => {
						this.$refs.groupsTable.bodyWrapper.scrollTop = 0
					})

					var _this = this;
					setTimeout(function () {
						temp.forEach((item) => {
							_this.tableDataGroups.forEach((item2) => {
								if (item.id == item2.id) {
									_this.$refs.groupsTable.toggleRowSelection(item2,
										true);
									return;
								}
							});
						});
					}, 300);

				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					});
				}

			});


		},
		fastQueryMyGroups() { //快速查询组别
			this.currPageGroup = 1;
			this.queryGroupsettinByPlayers();
		},
		openGroupDialog() { //打开组别对话框
			this.grouprForm = {};
			if (!this.selectedPlayers || this.selectedPlayers.length <= 0) {
				Message({
					type: 'error',
					message: '请选择参赛选手!',
					duration: 5000
				});

				return;
			}
			this.groupDialogVisible = true;
			this.dialogSelectedGroups = [];
			this.dialogSelectedGroups = [...this.selectedGroups];
			this.fastQueryMyGroups();
		},
		closeGroupDialogVisible() {
			this.groupDialogVisible = false;
			this.selectedGroups = this.dialogSelectedGroups;
		},
		selectTableGroupsAll(selection) { //选择所有的组别
			if (selection.length <= 0) {
				this.dialogSelectedGroups = [];
			} else {
				this.dialogSelectedGroups = [];
				selection.forEach((item) => {
					this.dialogSelectedGroups.push(item);
				});
			}

		},

		selectTableGroups(selection, row) { //选择组别			
			var temp = [];
			var isExistFlag = false;
			this.dialogSelectedGroups.forEach((item) => {
				if (item.id == row.id) {
					isExistFlag = true;
				} else {
					temp.push(item);
				}
			});
			if (isExistFlag) {
				this.dialogSelectedGroups = temp;
			} else {
				if (!this.dialogSelectedGroups) {
					this.dialogSelectedGroups = [];
				}
				this.dialogSelectedGroups.push(selection[selection.length - 1]);
			}

		},
		calcSignupFee() {
			if (this.selectedGroups && this.selectedGroups.length > 0) {
				this.signupFee = 0;
				this.selectedGroups.forEach((item) => {
					if (item.chargingWay == '1') { //按组别收费
						this.signupFee += item.signupFee;
					} else { //按按人数收费
						this.signupFee += item.signupFee * this.selectedPlayers.length;
					}
				})
			} else {
				this.signupFee = 0;
			}
		},
		calcGroupSignupFee() { //计算组别弹框上的合计费
			if (this.dialogSelectedGroups && this.dialogSelectedGroups.length > 0) {
				this.groupSignupFee = 0;
				this.dialogSelectedGroups.forEach((item) => {
					if (item.chargingWay == '1') { //按组别收费
						this.groupSignupFee += item.signupFee;
					} else { //按按人数收费
						this.groupSignupFee += item.signupFee * this.selectedPlayers.length;
					}
				})
			} else {
				this.groupSignupFee = 0;
			}
		},
		removeSelectGroup(groupId) {
			this.tableDataGroups.forEach((item) => {
				if (item.id == groupId) {
					this.$refs.groupsTable.toggleRowSelection(item, false);
					return;
				}
			});
			//从选择的选手中删除
			var temp1 = this.selectedGroups.filter(item => item.id != groupId);
			this.selectedGroups = temp1;

			var temp2 = this.dialogSelectedGroups.filter(item => item.id != groupId);
			this.dialogSelectedGroups = temp2;


		},
		toPage(path) {
			this.$router.push({
				path: path
			});
		},
		submitOnlineSignup() { //提交报名数据
			if (this.form.teamSignupAuth == '1') {
				Message({
					type: 'error',
					message: ' 代表队已禁止报名!',
					duration: 5000
				});
				return;
			}
			var requestData = {};
			requestData.matchId = this.matchId;
			requestData.teamId = this.form.teamId;
			requestData.playerIds = [];
			this.selectedPlayers.forEach((item) => {
				requestData.playerIds.push(item.id);
			});
			requestData.groupCodes = [];
			this.selectedGroups.forEach((item) => {
				requestData.groupCodes.push(item.code);
			});
			requestData.comment = this.form.comment;
			handleSubmitOnlineSignup(requestData).then(res => {
				if (res.status == 200) {
					Message({
						type: 'success',
						message: res.msg,
						duration: 5000
					});
					this.$router.go(0);
				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					});
				}
			});
		},
		fastpageQuerySignupResult() {
			this.currPageSignupResult = 1;
			this.pageQuerySignupResult();
		},
		pageQuerySignupResult() { //分页查询报名结果
			handlePageQuerySignupResult({
				matchId: this.matchId,
				con1: this.signupResultForm.con1,
				con2: this.signupResultForm.con2,
				teamId: this.signupResultForm.teamId,
				pageSize: this.pageSizeSignupResult,
				currPage: this.currPageSignupResult,
				userType: '1'
			}).then(res => {
				if (res.status == 200) {
					var tempArr = [];
					// for (var i = 0; i < 100; i++) {
					// 	tempArr = tempArr.concat(res.data);
					// }
					tempArr = res.data;
					this.tableDataSignupResult = tempArr;
					this.currPageSignupResult = res.currPage;
					this.pageSizeSignupResult = res.pageSize;
					this.totalCountSignupResult = res.totalCount;
					this.sumSignupFee = res.extendData.sumSignupFee ? res.extendData.sumSignupFee : 0;
					this.$nextTick(() => {
						this.$refs.table1.bodyWrapper.scrollTop = 0
					})
				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					});
				}

			});
		},
		openTeamDialog() {
			this.queryMyTeams();
			this.teamFormDialogVisible = true;
		},
		adddelegateteamdialogEvent() { //打开新增代表队
			this.$refs.adddelegateteamdialog.showDialog();
		},
		addPlayerDialogEvent() { //打开新增选手对话框
			this.$refs.addplayerdialog.showDialog();
		},
		toRealNameAuth() { //跳转到实名认证
			this.$router.push({
				path: '/joinmatchrealnameauth'
			})

		},
		deletePlayer(id, state) { //删除选手
			var tips = state == '1' ? '您要删除的数据，已经通过审核，不能直接删除；即将向比赛方提交撤消申请' 
					:(state == '2'?'该数据处于申请撤消状态，确认恢复此报名数据？':'请确认是否删除当前选择的数据?') ;
			this.$confirm(tips, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				confirmButtonClass: 'confirmButtonClass'
			}).then(() => {
				handledeleteSignupPlayer(id).then(res => {
					if (res.status == 200) {
						this.$message({
							type: 'success',
							message: res.msg,
						});
						this.pageQuerySignupResult();
					} else {
						this.$message({
							type: 'error',
							message: res.msg,
						});
					}
				});


			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		queryMatchRelateSet() { //获取赛事相关设置
			handleQueryMatchRelateSet(this.matchId).then(res => {
				if (res.status == 200) {
					this.matchRelateSet = res.data;
				} else {
					this.$message({
						type: 'error',
						message: res.msg,
					});
				}
			})
		},
		selectInit(row) { //判断组别是否可选择
			if (row.allowSelectFlag == '1') {
				return false //不可勾选
			} else {
				return true //可勾选
			}
		},
		closePlayerDalog() { //关闭选手弹框
			var sexStr = "";
			this.selectedPlayers = this.dialogSelectedPlayers;
			this.selectedPlayers.forEach((item) => {
				sexStr += (item.sex + "");
			});
			if ("00" == sexStr) {
				this.$message({
					type: 'error',
					message: "不支持'男男'组别类型",
				});
				return;
			}
			this.playerDialogVisible = false;
			this.selectedGroups = [];

		},
		download() { //下载报名表
			this.signupResultForm.matchId = this.matchId;
			this.signupResultForm.userType = "1";
			handleDownloadOnlineSignupTable(this.signupResultForm).then(res => {
				var fileName = "报名表.xls";
				var blob = new Blob([res], {
					type: "application/octet-stream"
				});
				if (window.navigator.msSaveOrOpenBlob) { //msSaveOrOpenBlob方法返回bool值

					navigator.msSaveBlob(blob, fileName); //本地保存
				} else {

					var link = document.createElement('a'); //a标签下载
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName;
					link.click();
					window.URL.revokeObjectURL(link.href);
				}
			});

		},
		selectSignupPlayers(selection) { //选择报名选手
			if (!selection) {
				return;
			}
			this.deleteSelectedPlayers = [];
			selection.forEach((item) => {
				this.deleteSelectedPlayers.push(item.id);
			})

		},
		batchDeleteSignupPlayers() { //批量删除选手

			handleDeleteSignupPlayers({
				idList: this.deleteSelectedPlayers
			}).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: res.msg,
					});
					this.currPageSignupResult = 1;
					this.pageQuerySignupResult();
				} else {
					this.$message({
						type: 'error',
						message: res.msg,
					});
				}
			});

		},
		selectInitSignupResultTable(row) { //判断报名结果表中数据是否可以删除
			if (row.state == '1') {
				return false //不可勾选
			} else {
				return true //可勾选
			}
		},
		myTeamOfSignup() { //我的报名中得代表队
			handleMyTeamOfSignup(this.matchId).then(res => {
				if (res.status == 200) {
					this.myTeamsOfSignup = res.data;
				} else {
					this.$message({
						type: 'error',
						message: res.msg,
					});
				}
			});
		},
		matchdetails() { //比赛详情
			handleStreamlineDetails(this.matchId).then(res => {
				if (res.status == 200) {
					this.matchInfo = res.data
				} else {
					this.$message({
						type: 'error',
						message: res.msg,
						uration: 5000
					});
				}
			});
		},
		recoveryPlayerEvent(playerId) {

		}

	}
}