import request from '@index/utils/request'

export function handlePageQuerySignupResult(data) { //分页查询报名结果
  return request({
    url: 'onlinesignup/pageQuerySignupResult',
    method: 'post',
    data
  })
}


export function handleDeleteSignupPlayer(id) { //删除选手(需要验证操作者是否具有权限)
  return request({
    url: 'onlinesignuptable/deleteSignupPlayer',
    method: 'post',
    params:{id:id}
  })
}


export function handleAuthSignupPlayers(data) { //审核报名选手
  return request({
    url: 'onlinesignuptable/authSignupPlayers',
    method: 'post',
    data
  })
}



export function handleQueryTeamOfMatch(matchId) { //审核报名选手
  return request({
    url: 'onlinesignuptable/queryTeamOfMatch',
    method: 'post',
    params:{matchId:matchId}
  })
}


export function handleDownloadOnlineSignupTable(data) { //下载正式报名报
  return request({
    url: 'onlinesignuptable/download',
    method: 'post',
	responseType: 'blob',
   data
  })
}


export function handleQueryRenamePlayersByMatchId(matchId) { //根据赛事id查询重名选手
  return request({
    url: 'onlinesignup/queryRenamePlayersByMatchId',
    method: 'get',
    params:{matchId:matchId}
  })
}
