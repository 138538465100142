import request from '@index/utils/request'

export function handleJoinTeamSave(data) { //保存参赛队信息
  return request({
    url: 'joindelegateteam/save',
    method: 'post',
    data
  })
}


export function handleViewDetails(id) { //参赛队详情
  return request({
    url: 'joindelegateteam/viewDetails',
    method: 'post',
    params: {
      id: id
    }
  })
}



export function handlePageQuery(data) { //分页查询
  return request({
    url: 'joindelegateteam/pageQuery',
    method: 'post',
    data
  })
}


export function handleStartStopTeam(id) { //启用停用代表队
  return request({
    url: 'joindelegateteam/startStopTeam',
    method: 'post',
    params: {
      id: id
    }
  })
}
