import request from '@index/utils/request'

export function handleMyTeams(matchId) { //我的代表队
  return request({
    url: 'joindelegateteam/myTeams',
    method: 'post',
    params:{matchId:matchId}
  })
}


export function handlePageQueryPlayers(data) { //分页查询选手
  return request({
    url: 'player/pageQueryPlayers',
    method: 'post',
    data
  })
}


export function handleQueryMatchRelateSet(matchId) { //根据赛事id获取设置信息
  return request({
    url: 'matchrelateset/queryMatchRelateSet',
    method: 'post',
    params: {
      matchId: matchId
    }
  })
}



export function handleQueryGroupsettinByPlayers(data) { //根据选手查询对应的参赛组别
  return request({
    url: 'groupsetting/queryGroupsettinByPlayers',
    method: 'post',
    data
  })
}



export function handleMyPlayerClassify(data) { //根据选手查询对应的参赛组别
  return request({
    url: 'playerClassify/myPlayerClassify',
    method: 'post',
    data
  })
}


export function handleSubmitOnlineSignup(data) { //在线报名
  return request({
    url: 'onlinesignup/submitOnlineSignup',
    method: 'post',
    data
  })
}



export function handlePageQuerySignupResult(data) { //分页查询报名结果
  return request({
    url: 'onlinesignup/pageQuerySignupResult',
    method: 'post',
    data
  })
}


export function handledeleteSignupPlayer(id) { //删除报名选手
  return request({
    url: 'onlinesignup/deleteSignupPlayer',
    method: 'post',
    params: {
      id: id
    }
  })
}


export function handleSaveCurrentTeam(data) { //保存当前代表队
  return request({
    url: 'currentteam/saveCurrentTeam',
    method: 'post',
    data
  })
}


export function handleQueryCurrentTeam(matchId) { //查询当前代表队(记录最新代表队)
  return request({
    url: 'currentteam/queryCurrentTeam',
    method: 'post',
    params:{matchId:matchId}
  })
}



export function handleDeleteSignupPlayers(data) { //批量删除报名选手
  return request({
    url: 'onlinesignup/deleteSignupPlayers',
    method: 'post',
   data
  })
}



export function handleMyTeamOfSignup(matchId) { //我的报名中得代表队
  return request({
    url: 'onlinesignup/myTeamOfSignup',
    method: 'post',
    params:{matchId:matchId}
  })
}
