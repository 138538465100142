import request from '@index/utils/request'

//选手分类***************************************************************************************
export function handlePlayerClassifySave(data) { //选手分类、修改
	return request({
		url: 'playerClassify/save',
		method: 'post',
		data
	})
}


export function handleDeletePlayerClassify(id) { //删除分类
	return request({
		url: 'playerClassify/deletePlayerClassify',
		method: 'post',
		params: {
			id: id
		}
	})
}


export function handlePagePlayerClassify(data) { //分页查询分类
	return request({
		url: 'playerClassify/pagePlayerClassify',
		method: 'post',
		data
	})
}

export function handleMyPlayerClassify(data) { //我的选手分类
	return request({
		url: 'playerClassify/myPlayerClassify',
		method: 'post',
		data
	})
}

export function handlePlayerClassifyDetails(id) { //选手分类详情
	return request({
		url: 'playerClassify/playerClassifyDetails',
		method: 'post',
		params: {
			id: id
		}
	})
}




//选手*****************************************************************
export function handleSavePlayer(data) { //保存选手
	return request({
		url: 'player/save',
		method: 'post',
		data
	})
}


export function handleDeletePlayers(data) { //删除选手
	return request({
		url: 'player/deletePlayers',
		method: 'post',
		data
	})
}

export function handlePageQueryPlayers(data) { //分页查询选手
	return request({
		url: 'player/pageQueryPlayers',
		method: 'post',
		data
	})
}



export function handleBatchImportPlayers(data) { //批量导入选手
	return request({
		url: 'player/batchImportPlayers',
		method: 'post',
		data
	})
}

export function handleDistinguishCardNo(cardNO) { //识别证件号(出生年月日和性别)
	return request({
		url: 'player/distinguishCardNo',
		method: 'post',
		params: {
			cardNO: cardNO
		}
	})
}

export function handleBatchPlayersClassify(data) { //批量更新选手分类
	return request({
		url: 'player/batchPlayersClassify',
		method: 'post',
		data
	})
}

export function handlePlayerDetails(id) { //选手详情
	return request({
		url: 'player/playerDetails',
		method: 'post',
		params: {
			id: id
		}
	})
}


export function handleDownloadPlayers(data) { //下载选手
	return request({
		url: 'player/download',
		method: 'post',
		responseType: 'blob',
		data
	})
}



export function handlePageQueryPrePlayers(data) { //分页查询准选手
	return request({
		url: 'out/prePlayer/pageQueryPrePlayers',
		method: 'post',		
		data
	})
}


export function handleDeletePrePrePlayers(data) { //分页查询准选手
	return request({
		url: 'out/prePlayer/deletePrePrePlayers',
		method: 'post',		
		data
	})
}

export function handleStatisticsWaitImportPrePlayers() { // 统计待导入选手
	return request({
		url: 'out/prePlayer/statisticsWaitImportPrePlayers',
		method: 'post'
	})
}


export function handleImportPrePlayers(data) { // 批量导入准选手
	return request({
		url: 'out/prePlayer/importPrePlayers',
		method: 'post',
		data
	})
}

