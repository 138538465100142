<!--新增选手对话框 -->
<template>
	<div>
		<el-dialog class="matchDialog" destroy-on-close :title="addFlag?('新增选手'):'编辑选手'"
			:visible.sync="addPlayerDialogVisible" :close-on-click-modal="false" width="650px">
			<div>
				<el-form label-width="80px" ref="playerForm" :model="playerForm" :rules="playerFormRules"
					inline-message>
					<el-form-item label="分类" prop="classifyId">
						<el-select size="small" v-model="playerForm.classifyId" placeholder="选择分类" style="width: 100%;">
							<el-option v-for="item in allPlayersClassifys" :key="item.id" :label="item.classifyName"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="姓名" prop="name">
						<el-input size="small" class="input-item" style="margin-top:0px;" maxlength="50" v-model="playerForm.name"
							placeholder="请输入选手姓名" :clearable="true">
						</el-input>
					</el-form-item>
					<el-form-item label="证件类型" prop="cardType">
						<el-radio-group size="small" v-model="playerForm.cardType" @change="selectCardType">
							<el-radio label="0">身份证</el-radio>
							<el-radio label="1">其他</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="证件号码" prop="cardNO">
						<el-input size="small" class="input-item" maxlength="18" style="margin-top:0px;" v-model="playerForm.cardNO"
							placeholder="请输入证件号码" :clearable="true" @input="distinguishCardNo">
						</el-input>
					</el-form-item>
					<el-form-item label="性别" prop="sex">
						<el-radio-group v-model="playerForm.sex" :disabled="forbidInput">
							<el-radio label="0">男</el-radio>
							<el-radio label="1">女</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="出生日期" prop="birthday">
						<el-date-picker size="small" type="date" placeholder="选择日期" :disabled="forbidInput"
							v-model="playerForm.birthday" style="width: 100%;">
						</el-date-picker>
					</el-form-item>
					<div class="playerFormErrorTips" v-if="showPlayerFormErrorTips">您的证件号有误!</div>
				</el-form>
			</div>
			<template #footer>
				<el-button size="small" @click="closeAddPlayerDialogEvent">取 消</el-button>
				<el-button size="small" type="danger" @click="savePlayer" class="dialog-submit-btn"
					style="background-color: rgb(243, 44, 108);color:white;border-color:rgb(243, 44, 108);">{{addFlag?'确认新增':'保存'}}</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {

		Message
	} from 'element-ui'
	import {
		handleSavePlayer,
		handleDistinguishCardNo,
		handleMyPlayerClassify,
	} from '@index/api/team/playerinfo';

	export default {
		name: 'addplayerdialog',
		components: {

		},
		props: ['addFlag'],
		data() {
			return {
				showPlayerFormErrorTips: false,
				addPlayerDialogVisible: false,
				allPlayersClassifys: [],
				forbidInput: false,
				playerForm: {
					cardType:'0',
					birthday: '',
					cardNO:'',
					sex: ''
				},
				playerFormRules: {
					name: [{
						required: true,
						message: '请输入选手姓名',
						trigger: 'blur'
					}],
					cardType: [{
						required: true,
						message: '请选择证件类型',
						trigger: 'blur'
					}]
				},
			};
		},
		watch: {

		},
		created() {
			this.myPlayerClassify(); //加载分类	
		},
		mounted() {

		},
		destroyed() {
			// window.removeEventListener('storage', this.afterQRScan)

		},
		methods: {
			showDialog() {
				this.addPlayerDialogVisible = true;
			},
			myPlayerClassify() {
				handleMyPlayerClassify({

				}).then(res => {
					if (res.status == 200) {
						this.allPlayersClassifys = res.data;
					} else {
						Message({
							message: res.msg,
							type: 'error',
							duration: 5 * 1000
						})
					}
				});
			},
			savePlayer(callback) { //新增选手
				this.$refs.playerForm.validate((valid) => {
					if (valid) {
						handleSavePlayer(this.playerForm).then(res => {
							if (res.status == 200) {
								Message({
									message: res.msg,
									type: 'success',
									duration: 5 * 1000
								})
								this.addPlayerDialogVisible = false;
								this.playerForm = {
									birthday: '',
									sex: '',
									cardNO:'',
									cardType:'0'
								};
								if (callback) {
									callback();
								}
							} else {
								Message({
									message: res.msg,
									type: 'error',
									duration: 5 * 1000
								})
							}
						});
					} else {
						return false;
					}
				});
				return;

			},
			selectCardType(value) { //选择证件类型
				if ('0' == value) {
					this.forbidInput = true;
				} else {
					this.forbidInput = false;
				}
				this.playerForm.cardNO="";
				this.playerForm.sex = "";
				this.playerForm.birthday = "";
				this.showPlayerFormErrorTips=false;

			},
			distinguishCardNo(cardNO) { //身份证自动识别
				if (this.playerForm.cardType != '0') {
					return;
				}
				this.playerForm.sex = "";
				this.playerForm.birthday = "";
				if (!(cardNO && (cardNO.length == 15 || cardNO.length == 18))) {
					return;
				}
				handleDistinguishCardNo(cardNO).then(res => {
					if (res.status == 200) {
						this.playerForm.sex = res.data.sex;
						this.playerForm.birthday = res.data.birthday;
						this.showPlayerFormErrorTips = false;

					} else {
						this.playerForm.sex = "";
						this.playerForm.birthday = "";
						this.showPlayerFormErrorTips = true;

					}
				});
			},
			closeAddPlayerDialogEvent() {
				this.playerForm = {
					birthday: '',
					sex: '',
					cardNO:'',
					cardType:'0'
				};
				this.addPlayerDialogVisible = false;
			}

		}
	}
</script>

<style>
	.playerFormErrorTips {
		color: red;
		text-align: right;
		margin-right: 30px;
	}
</style>